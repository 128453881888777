.main-wrap .banner {
  position: relative;
  text-align: center;
  height: 360px;
}
.main-wrap .banner .main-banner {
  background: url(../img/home_banner-1.png) center center no-repeat;
  height: 360px;
}
.main-wrap .banner .data {
  position: absolute;
  padding-top: 30px;
  left: 50%;
  margin-left: 260px;
  top: 50px;
  right: 0;
  width: 240px;
  height: 220px;
  background: rgba(2, 25, 61, 0.6);
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-radius: 10px;
}
.main-wrap .banner .data div {
  margin: 27px 0;
}
.main-wrap .banner .data div strong {
  background: -webkit-linear-gradient(#83baff, #2c7ffa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #2c7ffa;
  font: normal 60px/1 Arial;
}
.main-wrap .banner .data div sub {
  margin-left: 5px;
  font-size: 28px;
  font-family: Microsoft Yahei;
  color: #fff;
  vertical-align: baseline;
}
.main-wrap .banner .data span {
  margin: 0 3px;
  background: -webkit-linear-gradient(#83baff, #2c7ffa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #2c7ffa;
  font-size: 18px;
  font-family: Arial;
  font-weight: bold;
}
.main-wrap .banner .data p {
  margin-bottom: 20px;
}
.main-wrap .content {
  margin: 0 auto;
  padding: 60px 0 50px;
  width: 1000px;
}
.main-wrap .content .advantage {
  margin-bottom: 85px;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.main-wrap .content .advantage h3 {
  margin: 40px 0 25px;
  font-size: 20px;
  font-weight: normal;
  color: #333;
}
.main-wrap .content .advantage p {
  font-size: 14px;
  line-height: 1.8;
  color: #666;
}
.main-wrap .content .advantage p a {
  display: inline;
  text-decoration: underline;
}
.main-wrap .content .guide {
  margin-bottom: 80px;
  padding: 0 110px;
  display: flex;
  justify-content: space-between;
}
.main-wrap .content .guide .fresh-guide,
.main-wrap .content .guide .exchange-account {
  width: 320px;
  height: 130px;
  border-radius: 10px;
  box-shadow: 0 0 10px #b7c5d9;
}
.main-wrap .content .guide .fresh-guide:hover,
.main-wrap .content .guide .exchange-account:hover {
  box-shadow: 0 0 10px #8390a1;
}
.main-wrap .content .guide .fresh-guide {
  background-image: url(../img/home_Guide.png);
}
.main-wrap .content .guide .exchange-account {
  box-sizing: border-box;
  padding-top: 80px;
  background-image: url(../img/home_Tur.png);
  color: #148eff;
  font-size: 26px;
  text-align: center;
}
.main-wrap .content .product .table-box {
  margin-bottom: 60px;
  overflow: hidden;
  border-radius: 10px;
}
.main-wrap .content .product .table-box .tab-header {
  overflow: hidden;
  margin: -10px 0 3px -30px;
}
.main-wrap .content .product .table-box .tab-header li {
  float: left;
  margin-right: 20px;
  height: 36px;
  padding: 0 20px;
  color: #333;
  font-size: 16px;
  text-align: center;
  line-height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.main-wrap .content .product .table-box .tab-header li.active,
.main-wrap .content .product .table-box .tab-header li:hover {
  background-color: #3f7ce1;
  color: #fff;
}
.main-wrap .content .product .table-box .tab-content li {
  display: none;
}
.main-wrap .content .product .table-box .tab-content li.show {
  display: list-item;
}
.main-wrap .content .product .table-box.goods .tab-header li.active,
.main-wrap .content .product .table-box.goods .tab-header li:hover {
  background-color: #643939;
}
.main-wrap .content .product .table-header {
  position: relative;
  height: 70px;
  background-color: orange;
  color: #fff;
}
.main-wrap .content .product .table-header h3 {
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  line-height: 70px;
}
.main-wrap .content .product .table-header a {
  position: absolute;
  right: 40px;
  top: 30px;
  color: #fff;
  padding-bottom: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.main-wrap .content .product .table-content {
  padding: 30px 50px 40px;
}
.main-wrap .content .product table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}
.main-wrap .content .product table th:nth-child(1),
.main-wrap .content .product table td:nth-child(1) {
  width: 100px;
}
.main-wrap .content .product table th:nth-child(2),
.main-wrap .content .product table td:nth-child(2) {
  width: 263px;
}
.main-wrap .content .product table th:nth-child(3),
.main-wrap .content .product table td:nth-child(3) {
  width: 188px;
}
.main-wrap .content .product table th:nth-child(4),
.main-wrap .content .product table td:nth-child(4) {
  width: 189px;
}
.main-wrap .content .product table th:nth-child(5),
.main-wrap .content .product table td:nth-child(5) {
  width: 160px;
}
.main-wrap .content .product table th,
.main-wrap .content .product table td {
  font-size: 16px;
}
.main-wrap .content .product table th {
  font-weight: normal;
  height: 50px;
  color: #333333;
}
.main-wrap .content .product table tr:last-child td {
  border: none;
}
.main-wrap .content .product table td {
  height: 62px;
  color: #666666;
  border-bottom: 1px solid #ddd;
}
.main-wrap .content .product table td a,
.main-wrap .content .product table td.orange {
  transform: translateY(4px);
}
.main-wrap .content .product table td.orange {
  color: #fb9b13;
}
.main-wrap .content .product table td:first-child {
  width: 100px;
}
.main-wrap .content .product table td:last-child {
  width: 160px;
}
.main-wrap .content .product table td a:hover {
  text-decoration: underline;
}
.main-wrap .content .product table td a.button {
  float: right;
  width: 90px;
  height: 36px;
  color: #fff;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  border-radius: 4px;
}
.main-wrap .content .product table td a.button:hover {
  text-decoration: none;
}
.main-wrap .content .product table .medal {
  float: left;
  width: 58px;
  height: 53px;
}
.main-wrap .content .product table .medal.gold {
  background-image: url(../img/home_medal-Gold.png);
}
.main-wrap .content .product table .medal.silver {
  background-image: url(../img/home_medal-Silver.png);
}
.main-wrap .content .product table .medal.bronze {
  background-image: url(../img/home_medal-Bronze.png);
}
.main-wrap .content .product .futures h3 {
  background: #4e72a5;
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#638dc6), to(#304d79));
}
.main-wrap .content .product .futures td a.button {
  background-color: #5177ab;
}
.main-wrap .content .product .futures td a.button:hover {
  background-color: #415f88;
}
.main-wrap .content .product .goods h3 {
  background: #733b3d;
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#8a4f50), to(#5d2829));
}
.main-wrap .content .product .goods td a.button {
  background-color: #854b4c;
}
.main-wrap .content .product .goods td a.button:hover {
  background-color: #643939;
}
.main-wrap .content .product .foreign h3 {
  background: #5cadb0;
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#5cadb0), to(#316b6d));
}
.main-wrap .content .product .foreign td a.button {
  background-color: #58a6a9;
}
.main-wrap .content .product .foreign td a.button:hover {
  background-color: #468688;
}
.main-wrap .content .product .mix h3 {
  background: #c59361;
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#c59361), to(#7e5b38));
}
.main-wrap .content .product .mix td a.button {
  background-color: #bd8c5c;
}
.main-wrap .content .product .mix td a.button:hover {
  background-color: #a47242;
}
.main-wrap .content .section {
  margin-bottom: 80px;
}
.main-wrap .content .news,
.main-wrap .content .index-num {
  box-sizing: border-box;
  padding: 30px 40px;
  border-radius: 10px;
}
.main-wrap .content .news h4,
.main-wrap .content .index-num h4 {
  margin-bottom: 24px;
  color: #333333;
  font-size: 18px;
  font-weight: normal;
}
.main-wrap .content .news h4 span,
.main-wrap .content .index-num h4 span {
  padding-bottom: 3px;
  border-bottom: 1px solid #333;
}
.main-wrap .content .news h4 a,
.main-wrap .content .index-num h4 a {
  float: right;
  color: #666666;
  font: normal 14px Arial;
}
.main-wrap .content .news h4 a:hover,
.main-wrap .content .index-num h4 a:hover {
  color: #2991e2;
}
.main-wrap .content .news {
  float: left;
  width: 650px;
  height: 400px;
  font-size: 14px;
  color: #666;
}
.main-wrap .content .news .news-brief {
  height: auto;
  overflow: hidden;
  margin-bottom: 20px;
  white-space: normal;
}
.main-wrap .content .news .news-brief:before {
  display: none !important;
}
.main-wrap .content .news .news-brief:hover h5 {
  color: #2991e2;
}
.main-wrap .content .news .news-brief:hover p {
  text-decoration: underline;
  color: #47aaf6;
}
.main-wrap .content .news .news-brief img {
  float: left;
  overflow: hidden;
  margin-right: 30px;
  width: 290px;
  height: 150px;
  border-radius: 10px;
}
.main-wrap .content .news .news-brief h5 {
  margin: 15px 0;
  color: #333;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  line-height: 1;
}
.main-wrap .content .news .news-brief p {
  line-height: 1.5;
  color: #666;
}
.main-wrap .content .news li {
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main-wrap .content .news li:before {
  content: '●';
  display: inline;
  margin-right: 5px;
}
.main-wrap .content .news li a {
  display: inline;
  color: #666;
}
.main-wrap .content .news li a:hover {
  color: #2991e2;
}
.main-wrap .content .index-num {
  float: right;
  width: 310px;
  height: 400px;
}
.main-wrap .content .index-num table {
  width: 100%;
}
.main-wrap .content .index-num table td {
  font-size: 16px;
  height: 36px;
}
.main-wrap .content .index-num table td:first-child {
  text-align: left;
}
.main-wrap .content .index-num table td:last-child {
  text-align: right;
  font-family: Arial;
}
.main-wrap .content .index-num table td.red {
  color: #f81212;
}
.main-wrap .content .index-num table td.green {
  color: #4ad495;
}
.main-wrap .content .cooperate h4 {
  margin-bottom: 30px;
  overflow: hidden;
  width: 80px;
  height: 0;
  padding-top: 20px;
  font-size: 0;
  background: url(../img/home_title.png) no-repeat;
}
.main-wrap .content .cooperate ul {
  padding: 0 1px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.main-wrap .content .cooperate ul li {
  margin-bottom: 30px;
}
.main-wrap .content .cooperate ul li a:hover img {
  box-shadow: 0 0 6px #7e9bb8;
  border-radius: 10px;
}
.main-wrap .content .cooperate ul img {
  width: 200px;
  height: 80px;
}
